<template>
<div>
     <div class="mb-2 alert alert-warning">
        <div class="py-2">
            <span class="mr-4 font-14"><strong>Your email address is not verified!</strong> We had sent a code to email during registration. <a :href="absoluteUrl(`/email/${activeUser.email}/verify`)" target="_blank" class="alert-link"> Click this link to verify!</a></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:"user-email-verification",
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
}
</script>
